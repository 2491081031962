/* eslint-disable */
<template>
  <div class="modal-backdrop delete-modal">
    <div class="modal">

			<section class="modal-body">
        <img src="@/assets/images/pdf_file_icon.png" alt="PDF FILE LOGO" />
        <div class="doc-to-delete">{{formatDisplay(docToDelete)}}</div>
        <span class="notify">Are your sure you want to delete this document?</span>
			</section>

      <footer class="modal-footer">
        <div class="button-div">
            <button
            type="button"
            class="btn-red"
            @click="deleteDocument"
          >
            Delete
          </button>

          <button
            type="button"
            class="btn-gray"
            @click="close"
          >
            Cancel
          </button>
        </div>
        <div style="clear: both;"></div>
      </footer>
    </div>
  </div>
</template>

<script>
	/* eslint-disable */	
	import CookieHelper from "@/helpers/cookie-helper";

  export default {
    name: 'modal-delete',
    props: ['docToDelete'],
    
	  async created() {
	  },
	  async mounted() {
	  },
    methods: {
      close() {
        this.$emit('close');
      },
		  deleteDocument(){
        this.$emit('confirmDeleteDocument',this.docToDelete);
		  },
      formatDisplay(doc){
        let full_text = doc;
        let left_string = '';
        let right_string = '';
        if (doc.length>=25){
            left_string = full_text.substr(0,10);
            right_string = full_text.substr(full_text.length - 10);
            full_text = left_string + '...' + right_string;
        }
        return full_text;
      },
    },
  };
</script>

<style scoped>
  .doc-to-delete {
    word-break: break-all;
    font-size: 14px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 39%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 14%;
    border-radius: 20px;
    border: 1px solid #e9e9e9;
  }

  @media only screen and (max-width: 1440px) {
    .modal{
      width: 19%;
    }

	}
  @media only screen and (max-width: 1024px) {
    .modal{
      width: 25%;
    }
 
	}
	@media only screen and (max-width: 980px) {
    .modal{
      width: 28%;
    }
 
	}
  @media only screen and (max-width: 768px) {
	  .modal {
	    width: 35%;
	  }
	}
  @media only screen and (max-width: 430px) {
    .modal {
	    width: 62%;
	  }
	}
	@media only screen and (max-width: 425px) {
    .modal {
	    width: 60%;
	  }
	}
  @media only screen and (max-width: 375px) {
    .modal {
	    width: 65%;
	  }
		.search-input {
      padding: 10px 23px 10px 10px;
    }
	}
  @media only screen and (max-width: 320px) {
    .modal {
	    width: 80%;
	  }
	}

  .modal-header {
    padding: 15px;
    display: flex;
  }
  .modal-footer{
    display: flex;
    padding: 15px 44px 23px 44px;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #1677ff;
    justify-content: space-between;
  }

  .modal-footer {
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 26px 14px 16px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #1677ff;
    background: transparent;
  }

  .button-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .btn-red {
    color: white;
    background: #FF3717;
    border: 1px solid #FF3717;
    border-radius: 8px;
    padding: 6px;
    display: inline-block;
    text-align: center;
		font-size: 14px;
    cursor: pointer;
  }
  .btn-red:hover {
    background: #D4361C;
  }

  .btn-gray {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 6px;
    display: inline-block;
    text-align: center;
    min-width: 65px;
		font-size: 14px;
    cursor: pointer;
  }
  .btn-gray:hover {
    background: #F6F6F6;
  }
  .btn-gray:hover{
    background: #afaeae;
    border: 1px solid #afaeae;
    border-radius: 8px;
  }
  .notify{
    font-weight: bold;
    padding-top: 8px;
  }
  .modal-body img {
    margin-bottom: -8px;
  }

</style>