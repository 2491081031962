export default {
  getFlag(name, value) {
    let cookie_value = this.getCookie(name);

    if (cookie_value == "") {
      return value;
    } else {
      return cookie_value;
    }
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.ai-pro.org;path=/";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=api-chatpdf.ai-pro.org;path=/";
  },
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  updateCookie(cname, newValue, exdays) {
    let currentCookieValue = this.getCookie(cname);
    if (currentCookieValue !== "") {
      this.setCookie(cname, newValue, exdays);
    }else{
      this.setCookie(cname, newValue, exdays);
    }
  },
};
